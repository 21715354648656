import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const HomePage = React.lazy(() => import('./pages/home'));
const PrivacyPage = React.lazy(() => import('./pages/privacy-policy'));


function AppRouting() {
    return (
        <BrowserRouter>
            <Suspense fallback={null}>
                <Routes>
                    <Route path="/*" element={<HomePage />} />
                    <Route path="/privacy-policy" element={<PrivacyPage />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default AppRouting