import AppRouting from './app-routing';
import './App.css';

function App() {

  return (
    <>
      <AppRouting />
    </>
  );
}

export default App;